<template>
  <main role="main" class="container">
    <div style="padding-top: 1rem" class="d-none d-lg-block"></div>
    <div class="row" style="margin-bottom: 5px">
      <div class="col-sm-10">
        <div class="scrollable-div" v-html="log"></div>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="form-horizontal">
        <div class="form-group row">
          <div class="col-sm-8">
            <input
              type="text"
              class="form-control"
              id="input-box"
              placeholder="Enter text"
              v-model="message"
            />
          </div>
          <div class="col-sm-2">
            <button class="btn btn-primary" @click="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { OpenAI } from "langchain/llms/openai";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { initializeAgentExecutor, AgentExecutor } from "langchain/agents";
import { Calculator } from "langchain/tools/calculator";
import { PineconeClient } from "@pinecone-database/pinecone";
import { PineconeStore } from "langchain/vectorstores/pinecone";
import { VectorDBQAChain } from "langchain/chains";
import { SerpAPI, ChainTool } from "langchain/tools";

@Component
export default class App extends Vue {
  log: string = "";
  message: string = "";
  executor: AgentExecutor;

  mounted() {
    this.init();
  }

  async init() {
    const embeddings = new OpenAIEmbeddings({
      openAIApiKey: "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", // In Node.js defaults to process.env.OPENAI_API_KEY
    });

    const model = new OpenAI({
      temperature: 0,
      modelName: "gpt-3.5-turbo",
      openAIApiKey: "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",
    });


    const client = new PineconeClient();
    await client.init({
      apiKey: "f3003d01-4a5d-48c3-92c5-abb67b22534d",
      environment: "us-west1-gcp-free",
    });
    const pineconeIndex = client.Index("signalitem2");
    const vectorStore = await PineconeStore.fromExistingIndex(
      embeddings,
      { pineconeIndex }
    );
    
    const chain = VectorDBQAChain.fromLLM(model, vectorStore, {
      k: 10,
      returnSourceDocuments: true,
    });

    const qaTool = new ChainTool({
      name: "見積もり",
      description:
        "価格表",
      chain: chain,
    });

    const tools = [qaTool, new Calculator()];

    this.executor = await initializeAgentExecutor(
      tools,
      model,
      "zero-shot-react-description"
    );

    this.writeLog("Agent loaded.");
  }

  writeLog(message: string) {
    this.log += `<p>${message}</p>`;
  }

  async submit() {
    this.writeLog(`User: ${this.message}`);
    const result = await this.executor.call({ input: this.message });

    this.writeLog(`AI: ${result.output}`);
    this.message = "";
  }
}
</script>

<style scoped>
.scrollable-div {
  border: 1px solid gray;
  height: 400px;
  width: 100%;
  overflow-y: scroll;
}
</style>